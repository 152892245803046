import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flexing d-flex justify-end",
      staticStyle: {
        "margin-bottom": "0px",
        "min-height": "100vh",
        "padding-top": "75px",
        "background-color": "var(--dark)",
      },
      attrs: { id: "calendly-finish-container" },
    },
    [
      _c(
        "div",
        { staticClass: "nav-bar" },
        [
          _c(
            VCard,
            {
              staticClass: "ma-0 pa-0",
              staticStyle: {
                position: "absolute",
                top: "10px",
                left: "10%",
                display: "flex",
                "flex-direction": "row",
                "background-color": "var(--dark)",
                "box-shadow": "none !important",
              },
              attrs: { elevation: "0" },
            },
            [
              _c("img", {
                staticClass: "logo",
                attrs: {
                  src: require("../../../assets/DXA_Invest-logo.png"),
                  alt: "logo",
                },
              }),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-content" },
        [
          _c("span", { staticClass: "portfolio-created" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("portfolio_created_successfully")) + " "
            ),
          ]),
          _c("br"),
          _c(
            "p",
            {
              staticStyle: {
                color: "var(--white)",
                "font-size": "14px",
                "margin-top": "15px",
                width: "290px",
              },
              attrs: { id: "portfolio-message" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("portfolio_created_message")) + " ")]
          ),
          _c(
            "p",
            {
              staticStyle: {
                color: "var(--primary)",
                "font-size": "16px",
                "font-weight": "bold",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("about_your_checkin")) + " ")]
          ),
          _c(
            "div",
            { staticClass: "about-checkin-section" },
            _vm._l(_vm.checkinDetails, function (detail, index) {
              return _c(
                "span",
                { key: index, staticClass: "about-checkin-label" },
                [
                  _c(
                    VIcon,
                    {
                      staticClass: "check-icon",
                      attrs: { small: "", color: "primary" },
                    },
                    [_vm._v("mdi-check")]
                  ),
                  _vm._v(" " + _vm._s(_vm.$t(detail.name)) + " "),
                ],
                1
              )
            }),
            0
          ),
          _c("br"),
          _c(
            "p",
            {
              staticStyle: {
                color: "var(--primary)",
                "font-size": "16px",
                "font-weight": "bold",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("next_step") + ":") + " ")]
          ),
          _c(
            "p",
            [
              _c(
                VIcon,
                {
                  staticClass: "text-content-icon",
                  attrs: { color: "primary" },
                },
                [_vm._v("mdi-checkbox-blank-circle")]
              ),
              _c(
                "span",
                {
                  staticStyle: { color: "var(--white)", "font-weight": "700" },
                },
                [_vm._v(_vm._s(_vm.$t("your_first_check_in")))]
              ),
            ],
            1
          ),
          _c(
            "p",
            { staticStyle: { color: "var(--white)", "font-weight": "700" } },
            [
              _c(
                VIcon,
                { staticClass: "text-content-icon", attrs: { color: "white" } },
                [_vm._v("mdi-calendar-blank-outline")]
              ),
              _vm._v(" " + _vm._s(_vm.parseDate(_vm.date)) + " "),
            ],
            1
          ),
          _c(
            "p",
            { staticStyle: { color: "var(--white)", "font-weight": "700" } },
            [
              _c(
                VIcon,
                { staticClass: "text-content-icon", attrs: { color: "white" } },
                [_vm._v("mdi-timer-outline")]
              ),
              _vm._v(" " + _vm._s(_vm.formatHour(_vm.hour)) + " "),
            ],
            1
          ),
          _c(
            "p",
            {
              staticStyle: {
                color: "var(--primary)",
                "margin-bottom": "12px",
                "font-size": "12px",
              },
              attrs: { id: "invite-send-email" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("invite_send_to_email")) + " ")]
          ),
          _c("span", { staticClass: "finish-footer" }, [
            _vm._v(" " + _vm._s(_vm.$t("alpha_finish_footer")) + " "),
          ]),
          _c("DxaButton", {
            staticStyle: { width: "290px" },
            attrs: { title: _vm.$t("Access_dxa"), id: "dxaButton" },
            on: { function: _vm.goToPipeline },
          }),
          _c("br"),
        ],
        1
      ),
      !_vm.gs.isMobile()
        ? _c(VImg, {
            staticStyle: {
              position: "absolute",
              top: "0",
              left: "0",
              "z-index": "1 !important",
            },
            attrs: {
              "max-width": "85%",
              src: require("@/assets/dxa-background-welcome-page2.png"),
            },
          })
        : _vm._e(),
      !_vm.gs.isMobile()
        ? _c(VImg, {
            staticStyle: { position: "absolute", bottom: "25px", left: "25px" },
            attrs: {
              "max-width": "45%",
              src: require("@/assets/dxa-background-welcome-page3.png"),
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }